@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "JosefinSans-Thin";
  src: url(../assets/fonts/JosefinSans-Thin.ttf);
}
@font-face {
  font-family: "JosefinSans-ThinItalic";
  src: url(../assets/fonts/JosefinSans-ThinItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-ExtraLight";
  src: url(../assets/fonts/JosefinSans-ExtraLight.ttf);
}
@font-face {
  font-family: "JosefinSans-ExtraLightItalic";
  src: url(../assets/fonts/JosefinSans-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-Light";
  src: url(../assets/fonts/JosefinSans-Light.ttf);
}
@font-face {
  font-family: "JosefinSans-LightItalic";
  src: url(../assets/fonts/JosefinSans-LightItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-Regular";
  src: url(../assets/fonts/JosefinSans-Regular.ttf);
}
@font-face {
  font-family: "JosefinSans-Medium";
  src: url(../assets/fonts/JosefinSans-Medium.ttf);
}
@font-face {
  font-family: "JosefinSans-MediumItalic";
  src: url(../assets/fonts/JosefinSans-MediumItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-SemiBold";
  src: url(../assets/fonts/JosefinSans-SemiBold.ttf);
}
@font-face {
  font-family: "JosefinSans-SemiBoldItalic";
  src: url(../assets/fonts/JosefinSans-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-Bold";
  src: url(../assets/fonts/JosefinSans-Bold.ttf);
}
@font-face {
  font-family: "JosefinSans-BoldItalic";
  src: url(../assets/fonts/JosefinSans-BoldItalic.ttf);
}

@font-face {
  font-family: "Montserrat-Light";
  src: url(../assets/fonts/Montserrat-Light.ttf);
}@font-face {
  font-family: "Montserrat-Medium";
  src: url(../assets/fonts/Montserrat-Medium.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "JosefinSans-Light", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  color: #000000;
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.family-thin {
  font-family: "JosefinSans-Thin" !important;
}
.family-thin-italic {
  font-family: "JosefinSans-ThinItalic" !important;
}
.family-extra-light {
  font-family: "JosefinSans-ExtraLight" !important;
}
.family-extra-light-italic {
  font-family: "JosefinSans-ExtraLightItalic" !important;
}
.family-light {
  font-family: "JosefinSans-Light" !important;
}
.family-light-italic {
  font-family: "JosefinSans-LightItalic" !important;
}
.family-regular {
  font-family: "JosefinSans-Regular" !important;
}
.family-medium {
  font-family: "JosefinSans-medium" !important;
}
.family-medium-italic {
  font-family: "JosefinSans-MediumItalic" !important;
}
.family-semibold {
  font-family: "JosefinSans-SemiBold" !important;
}
.family-semibold-italic {
  font-family: "JosefinSans-SemiBoldItalic" !important;
}
.family-bold {
  font-family: "JosefinSans-Bold" !important;
}
.family-bold-italic {
  font-family: "JosefinSans-BoldItalic" !important;
}

.family-montlight {
  font-family: "Montserrat-Light" !important;
}
.family-montmedium {
  font-family: "Montserrat-Medium" !important;
}


.font-white {
  color: #ffffff !important;
}
.font-black {
  color: #000000 !important;
}
.font-pink {
  color: #d75f5f !important;
}
.font-gray {
  color: #746a69 !important;
}

.font-xs {
  font-size: 0.75rem;
}
.font-small {
  font-size: 0.875rem;
}
.font-normal {
  font-size: 1rem;
}
.font-medium {
  font-size: 1.2rem;
}
.font-xtra{
  font-size: 1.4rem;
}
.font-regular {
  font-size: 1.6rem;
}
.font-lg {
  font-size: 2rem;
}
.font-xl {
  font-size: 2.5rem;
}
.font-big {
  font-size: 3rem;
}

.back-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.back-top svg {
  color: #1a1a1a !important;
}
.box-number {
  background: rgba(255, 255, 255, 0);
  animation-name: box-show;
  animation-duration: 2s;
}
/* .topnav {
  background-size:cover;
  background: url(../assets/background/bgHeader.png) center top no-repeat;
} */

.menu-logo {
  margin-right: 36rem;
}


.mobile-container {
  max-width: 480px;
  margin: auto;
  color: white;
  border-radius: 10px;
}

.topnav {
  overflow: hidden;
  /* background-color: #333; */
  position: relative;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a.icon {
  /* background: black; */
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.topnavMobile a:hover {
  background-color: #ddd;
  color: black;
}

.active {
  background-color: #04AA6D;
  color: white;
}



.boxFaq{
justify-content: center;}

details summary {
    list-style: none;
  }


.collapse {
  /* border: solid 1px var(--gray-lighter);
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25); */
  margin-left: 0px;
  margin-right: 0px;
  text-align: center;
  max-width: 100%;
  transition: background-color 0.25s;
  will-change: background-color;
}
  .collapse :first-child {
    margin-top: var(--space);
  }

  .collapse :not(:last-child) {
    margin-bottom: var(--space-sm);
  }

  .collapse :hover {
    background-color: var(--gray-lighter);
  }
    /* .title:after {
      content: "🙂";
    }
  

  .collapse [open] .title:after {
      content: "😍";
    } */
  

.title {
  cursor: pointer;
  font-size: 20px;
  color: #396134;
  font-weight: 600;
  position: relative;
  text-align: center;
  padding: 30px 0px 60px 0px;

}
  .title:after {
    content: "ᐯ";
    font-size: 3rem;
    position: absolute;
    right: var(--space-sm);
    transform: translatey(-50%);
    top: 6rem;
    color: white;
  }


.description {
  border-top: var(--gray-light) solid 1px;
  font-size: 20px;
  line-height: 21px;
  padding: var(--space-sm);
  color: white;
  margin-left: 20rem;
  margin-right: 20rem;
}







.collapse2 {
  /* border: solid 1px var(--gray-lighter);
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25); */
  margin-left: 0px;
  margin-right: 0px;
  text-align: center;
  max-width: 100%;
  transition: background-color 0.25s;
  will-change: background-color;
}
.description2 {
  border-top: var(--gray-light) solid 1px;
  font-size: 20px;
  line-height: 21px;
  padding: var(--space-sm);
  color: white;
  margin-left: 2rem; 
  margin-right: 2rem; 
}
.title2 {
  cursor: pointer;
  font-size: 20px;
  color: #396134;
  font-weight: 600;
  position: relative;
  text-align: center;
  padding: 30px 0px 60px 0px;

}
  .title2:after {
    content: "ᐯ";
    font-size: 3rem;
    position: absolute;
    right: var(--space-sm);
    transform: translatey(-50%);
    top: 6rem;
    color: white;
  }




/*.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

 .topnav a:hover {
  color: black;
}

.topnav a.active {
  color: white;
}

.topnav .icon {
  display: none;
} */


.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  color: black;
}

.topnav a.active {
  color: white;
}

.topnav .icon {
  display: none;
}
.bg-topo {
  background: url(../assets/background/bgHeader.png) center top no-repeat;
  background-size: cover; 
  width: 100%;
  height: auto;
}
.imgVideo {
  height: 65rem;
  width: 100%;
  margin-bottom: -27rem;
}
.imgCTA {
  height: 20rem;
    width: 50%;
    margin-bottom: -10rem;
}
.imgSorocaba {
  height: auto;  
  width: 70%;
  margin-left: -10rem;
  margin-top: -40rem;

}
.indicacaoTopo {
  font-family: "JosefinSans-Bold";
  text-transform: uppercase;
  text-align: center;
  font-size: 1.25rem;
  color: #3b6036;
  border-radius: 1rem;
  border-width: 2px;
  border-color: white;
  background-color: white;
  border-style: solid;
  width: 170px;
  height: 30px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 8px 10px -6px rgb(0 0 0 / 10%);
  /* margin-top: 50px; */
}
.indicacaoText {
  font-size: 1.25rem;
  text-align: left;
  line-height: 1.5rem;
  font-family: "JosefinSans-Regular";
  color: white;
}
.tituloTopo {
  font-family: "JosefinSans-Bold";
  text-transform: uppercase;
  text-align: center;
  color: white;
  line-height: 1;
  font-weight: 900;

}
.descricaoTopo {
  font-size: 1.25rem;
  text-align: left;
  line-height: 1.5rem;
  font-family: "JosefinSans-Regular";
  text-transform: uppercase;
  color: white;
}
.descricao-box {
  display: flex;
  align-items: center;
}
.boxdescricao {
  padding-left: 7rem;
  padding-right: 14rem;
}
.descricaoBox {
  padding-left: 18rem;
  padding-right: 8rem;
}
 .descricao {
  display: flex;
  align-items: center;
  margin-top: 8rem;
  margin-bottom: 19rem;

}
.topoText {
  width: 70%;
}
.descricao-content {
  display: flex;
  flex-direction: column;
} 
.descricao-text {
  font-size: 1.2em;
  color: white;
  font-family: "JosefinSans-Thin";
}
.tituloModal {
  font-family: "Montserrat-Light";
  text-transform: uppercase;
  text-align: center;
  color: #d75f5f;
  font-size: 2.5rem;
  margin-top: 30px;
}
.inputModal{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #d75f5f;
  border-radius: 20px;
  font-size: 1.5rem;
}
.inputModal:focus {
  outline: none !important;
  border: 2px solid #d75f5f;
}
.modal-button { 
  font-size: 2rem;
  color: white;
  text-align: center;  
  font-family: "JosefinSans-SemiBold";
  align-self: center;
}
.button-img-modal {
  width: 50%;
  height: auto;
}
.borboleta-img-modal {
  width: 15%;
  height: auto;
  position: absolute;
  display: flex;
  margin-top: -5px;
  right: 60px;
}
.meia-borboleta-img-modal {
  width: 8%;
  height: auto;
  position: absolute;
  display: flex;
  margin-top: -70px;
  right: 40px;
}
.button-close-modal{
  display: flex;
  border-radius: 50%;
  border: 2px solid #CCCCCC;
  position: absolute;
    right: 10px;
    margin-top: -10px;
}
.button-close-modal:focus {
  border: 2px solid #CCCCCC;
}

.topo-button { 
  align-self: center; 
}
.button-topo {
  margin-top: -22rem;
  width: 80%;
  height: auto;
  padding-left: 14rem;
}

.imgSorocaba {
  /* margin-right: 120px; */
  width: auto;
  
}
.titulosorocaba {
  font-family: "JosefinSans-Medium";
  text-transform: uppercase;
  color: #d75f5f;
}
.text-sorocaba {
  font-size: 1rem;
  font-family: "JosefinSans-Medium";
}
.img-check {
  accent-color: #d75f5f;
}
.info-sorocaba {
  font-size: 1.2rem;
  font-family: "JosefinSans-Bold";
}
.whatsapp {
  color: green;
}
.titulo-etapas {
  font-family: "Montserrat-Light";
  text-transform: uppercase;
  color: #d75f5f;
  text-align: center;
}
.subtitulo-etapas {
  font-size: 1.5rem;
  color: #d75f5f;
  text-align: center;
  line-height: 1.5rem;
  font-family: "JosefinSans-Regular";
}
.img-etapas {
  height: 380px;
}
.descricao-etapas {
  color: white;
  text-align: center;
  font-family: "JosefinSans-SemiBold";
}
.botaoEtapas {
  margin-bottom: -30px;
}
.etapasButton {
  width: 280px;
  height: 60px;
}
.buttonEtapas {
  color: white;
  font-size: 1.75rem;
  font-family: "JosefinSans-SemiBold";
  align-self: center;
  text-transform: uppercase;
}
.bg-cadastro {
  background-size: cover; 
  background-color: #dcbe2d;  
}
.titulo-cadastro {
  font-family: "Montserrat-Light";
  text-transform: uppercase;
  color: white;
  text-align: center;
}
.w3-container {
  padding: 2em 2em 0.8em;
}
.cadastro {
  color: white;
  font-size: 1.75rem;
  font-family: "JosefinSans-Medium";
  margin-top: -12rem;
}
.sorocaba {
  color: white;
  font-size: 1.2rem;
  font-family: "JosefinSans-Light";
}

.box-bonus {
  border-radius: 1rem;
  border-width: 2px;
  border-color: rgb(244 114 182);
  border-style: solid;
  width: 360px;
  height: 155px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  margin-top: 50px;
}
.titulo-bonus {    
  color: #ad5677;
  font-family: "Montserrat-Light";
  text-transform: uppercase;
  font-size: 2.25rem;
}
.descricao-bonus{
  color: #ad5677;  
  font-size: 1rem;
  line-height: 1.2rem;
  font-family: "JosefinSans-SemiBold";
}

#target {
  width: 200%;
  padding: 0 100px 0 100px;
}

.botao-cadastro {
  color: white;
  text-transform: uppercase;
  font-family: "JosefinSans-Bold";
  font-size: 6rem;
}

.imgcadastro {
  width: 100%;
  height: auto;
}
.buttoncadastro {
  color: white;
  font-size: 1.75rem;
  font-family: "JosefinSans-SemiBold";
  align-self: center;
  text-transform: uppercase;
  margin-top: -30rem;
}
.button-garantia{
  width: 360px;
  height: 200px;
  margin-top: -46px;
}
.titulo-garantia {
  color: white;
  font-size: 1.75rem;
  font-family: "JosefinSans-SemiBold";
  margin-top: 30px;
}
.descricao-garantia {
  font-size: 1rem;
  color: white;
  line-height: 1.2rem;
  margin-top: 44px;

}
.titulo-dep {
  font-family: "Montserrat-Light";
  text-transform: uppercase;
  color: #d75f5f;
  text-align: center;
}
.subtitulo-dep {
  color: #d75f5f;
  text-align: center;
  font-family: "JosefinSans-SemiBold";
}
.video-dep {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.videoDep {
  width: 680px;
}

.titulo-faq {
  font-family: "JosefinSans-Regular";
  font-size: 1.15rem;
  color: #3b6036;
  text-align: center;
  line-height: 2px;
}
.subtitulo-faq {
  font-size: 1.15rem;
  color: black;
  text-align: center;
  line-height: 1rem;
  font-family: "JosefinSans-Regular";
}
.duvidas {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

}
.bg-footer {
  background: url(../assets/background/bg-footer.png) center top no-repeat;
  background-size: cover;
  margin-top: -10rem;
}
.footerText {
  color: black;
  font-size: 0.8rem;
  text-align: center;
}
.footerButton {
  margin-bottom: 3rem;
}
.buttonFooter {
  height: 150px;  
  width: auto;
}
.imgMac {
  width: 100%;
  height: auto;
}
.bars-tecnology {
  animation-name: slidein;
}
.btn-pink {
  background: white;
  width: 350px;
}
.btn-pink:hover {
  background: white;
}
.tec_dir {
  border-top-left-radius: 16px;
}
.tec_left {
  border-bottom-right-radius: 16px;
}
.strong {
  font-family: "Montserrat-Light";
}
.whats-message {
  z-index: 9;
}
.w3-modal-content {
  border-radius: 2em;
}

.w3-container {
  padding: 2em 2em 0.8em;
}

.modal-cancel {
    background-image: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: transparent;
    border-color: #ECECEC;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    color: #CCCCCC;
    height: 42px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0em;
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-top: 0;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.splide__pagination__page.is-active {
  background-color: #b40a7f !important;
}

.splide__arrow svg {
  fill: #ad5677 !important;
  padding-left: 14px;
  margin-right: 20px;
}

.accordion {
  background-color: white;
  color: black;
  cursor: pointer;
  width: 840px;
  height: 40px;
  border-radius: 0.375rem;
  border: 2px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  margin-top: 0.5rem;
  text-align: left;
  font-size: 15px;
  font-family: "JosefinSans-SemiBold";
  transition: 0.4s;
  padding-left: 20px;
  padding-right: 20px;
}

.boxAccordion{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}
 /*.accordion2{
  background-color: white;
  color: black;
  width: 100%; 
  display: flex;
  flex-direction: column;
  height: 40px; */
  /* border-radius: 0.375rem;
  border: 2px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1); 
  margin-top: 0.5rem;
  text-align: left;
  font-size: 15px;
  font-family: "JosefinSans-SemiBold";
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}*/

.panelAccordion{
  background-color: white;
  color: black;
  border-radius: 0.375rem;
  border: 2px;
  width: 100%;
}

.ant-collapse-content-box { 
  color: #8e8c8c;
  display: flex;
  flex-direction: column;
 }

 
/* SCROLLBAR */
@media only screen and (min-width: 600px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #d7c7bd;
  }

  
  *::-webkit-scrollbar {
    width: 2px;
  }

  *::-webkit-scrollbar-track {
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d7c7bd;
    border-radius: 20px;
    border: 3px solid #d7c7bd;
  }

  * {
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 1200px)  {
  .accordion2{
    width: 80%;
    display: flex;
  }
  .imgSorocaba {
    margin-bottom: -15rem;
  }
}
@media only screen and (min-width: 1600px)  {
  .accordion2{
    width: 100%;
    display: flex;
  }

  .boxFaq{
    padding: 0 1.25rem;
  }
  .imgSorocaba {
    height: auto;  
    width: 70%;
    margin-left: -10rem;
    margin-top: -40rem;
  
  }
}
@media only screen and (max-width: 1600px)  {
  .accordion2{
    width: 100%;
    display: flex;
  }

  .boxFaq{
    padding: 0 1.25rem;
  }
  .imgSorocaba {
    height: auto;  
    width: 70%;
    margin-left: -10rem;
    margin-top: -38rem;
  
  }
}
@media only screen and (max-width: 1200px)  {
  .accordion2{
    width: 100%;
    display: flex;
  }

  .boxFaq{
    padding: 0rem 1rem 5rem 1rem;
  }
  .imgSorocaba {
    height: auto;  
    width: 70%;
    margin-left: -10rem;
    margin-top: -40rem;
  
  }
}
@media only screen and (max-width: 600px) {
  .buttonReserva {
    font-size: 1.5rem;
  }
  .botaoVideo {
    font-size: 1.5rem;
  }
  .inputEmail{
    right: 10px;
  }
  .containerTopo{
    margin-top: 200px;
  }
  .titulo-faq {
    font-size: 1rem;
  }
  .subtitulo-faq {
    font-size: 1rem;
  }
  .bg-footer {
    background: url(../assets/background/bg-footer.png) center top no-repeat;
    background-size: cover;
    margin-top: -10rem;
  }
  .buttonFooter {
    height: 100px;  
    width: auto;
  }
  .duvidas {
    width: 250px;
    height: auto;
    padding-top: 3rem;
  }
  .imgVideo {
    height: 35rem;
    width: 90%;
    margin-bottom: -16rem;
    margin-top: -18rem;
    margin-right: -6rem;
  }
  .topnav #myLinks {
    display: none;
  }
  .descricaoTopo {
    font-size: 1rem;
    padding-right: 18rem;

  }
  .tituloTopo {
    font-weight: 800;
    font-size: 1.25rem;
    padding-right: 11rem;
    text-align: start;
  }
  .imgCTA {
    height: 10rem;
    width: 14rem;
    margin-right: -4rem;
    margin-bottom: 0rem;
}
.imgSorocaba {
  height: 20rem;  
  width: 100%;
  margin-left: 0rem;
  margin-top: 0rem;

}
.montagem {
  width: auto;
}
.imgParticipar {
  width: 14rem;
}
.sorocaba {
  margin-bottom: -8rem;
  padding-top: 0rem;
}
.topoText {
  width: 100%;
}
.descricao-text {
  font-size: 1.2em;
  color: black;
  font-family: "JosefinSans-Thin";
}
.boxdescricao {
  padding-left: 0rem;
  padding-right: 0rem;
}
.descricao-content {
  font-size: 1.5em;
  color: #04AA6D;
  text-transform: uppercase;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-color: #ecc400;
  text-decoration-style: solid;
  text-decoration-thickness: 4px;
  text-underline-offset: 1px;
  font-family: "JosefinSans-Bold";

} 
.descricao-text {
  font-size: 1rem;
  color: black;
  font-family: "JosefinSans-medium";
  line-height: 1.25;
}
.descricao {
  margin-bottom: 0rem;
}
.cadastro {
  margin-top: -6rem;
}
.botao-cadastro {
  margin-top: -60px;
  font-size: 2rem;
}
.buttoncadastro {
  margin-top: -10rem;
}
.imgcadastro {
  width: 100%;
  height: 60rem;
}
.bg-cadastro {
  padding-bottom: 0rem;
  
}
.bg-footer {
  background-size: contain;
  margin-top: -2rem;
}
.buttonFooter {
  margin-top: -6rem;
}
.title {
  padding: 30px 0px 50px 0px;

}
  .title:after {
    font-size: 3rem;
    top: 8rem;
  }


  .description {
    margin-left: 2rem; 
    margin-right: 2rem; 
    margin-top: 2rem;

}
#target {
  width: 25rem;  
  padding: 0 0 0 0;
}

}


@media only screen and (max-width: 400px) {
  .boxFaq{
    padding: 0rem 1rem 5rem 1rem;
  }
.imgVideo {
  margin-top: -20rem;
  margin-right: -5rem;
}
.descricaoTopo {
  font-size: 1rem;
  padding-right: 15rem;

}
.tituloTopo {
  font-weight: 800;
  font-size: 1.25rem;
  padding-right: 15rem;
  text-align: start;
}
.imgCTA {
 margin-right: -2rem;
}
.title:after {
  font-size: 3rem;
  top: 12rem;
}
.title {
  padding: 50px 0px 30px 0px;
}
}
/* SCROLLBAR */
